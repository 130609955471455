

<template>
  <div
    id="price"
    class="price pb-5"
  >
    <div class="price-top">
      <div class="top-img">
        <b-container>
          <b-row>
            <b-col cols="12">
        <h3>Price</h3>
        <b class="title">料金</b>
           </b-col>
          </b-row>
        </b-container>
      </div>
    </div>


    <div class="price-warp pt-5 pb-5 mb-4">
      <b-container class="bv-example-row pt-4">
        <div class="each_title">
          <div
            class="title"
          >Member</div>
          <p class="divide-title" />
          <small class="sub-title">料金システム</small>
        </div>
      </b-container>
    </div>

    <div class="group-lesson">
      <b-container
        fruid
        class="pt-4"
      >
        <b-row>
          <b-col cols="12">
            <div><h3>- group lesson</h3></div>
            <div>
              <b-card-group deck>
                <b-row>
                  <b-col
                    sm="6"
                    md="3"
                    class="pb-2"
                  >
                    <b-card
                      text-variant="black"
                      header="グループレッスン会員"
                      class="text-center"
                    >
                      <h4>￥9,350</h4>
                      <small>通い放題で効果を実感！</small>
                    </b-card>
                  </b-col>
                  <b-col
                    sm="6"
                    md="3"
                    class="pb-2"
                  >
                    <b-card
                      text-variant="black"
                      header="5 tickets"
                      class="text-center"
                    >
                      <h4>￥12,000</h4>
                      <small>自分のペースで通いたい！<br>有効期限３ヶ月</small>
                    </b-card>
                  </b-col>
                  <b-col
                    sm="6"
                    md="3"
                    class="pb-2"
                  >
                    <b-card
                      text-variant="black"
                      header="10 tickets"
                      class="text-center"
                    >
                      <h4>￥22,000</h4>
                      <small>自分のペースでお得に通いたい！<br>有効期限6ヶ月</small>
                    </b-card>
                  </b-col>
                  <b-col
                    sm="6"
                    md="3"
                    class="pb-2"
                  >
                    <b-card
                      text-variant="black"
                      header="ドロップイン"
                      class="text-center"
                    >
                      <h4>￥2,750</h4>
                      <small>定期的に通えない方に！</small>
                    </b-card>
                  </b-col>
                  <!-- <b-col
                    sm="6"
                    md="4"
                    class="pb-2"
                  >
                    <b-card
                      text-variant="black"
                      header="朝ヨガドロップイン"
                      class="text-center"
                    >
                      <h4>￥1,500</h4>
                      <small>朝だけ通ってみたい！</small>
                    </b-card>
                  </b-col> -->
                </b-row>
              </b-card-group>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--  personal lesson section-->
    <div class="personal-lesson">
      <b-container
        fruid
        class="pt-4"
      >
        <b-row class="pb-4">
          <b-col cols="12">
            <div><h3>- personal lesson</h3></div>
            <div>
              <b-card-group deck>
                <b-row>
                  <b-col
                    sm="6"
                    md="4"
                    class="pb-2"
                  >
                    <b-card
                      text-variant="black"
                      header="パーソナル会員"
                      class="text-center"
                    >
                      <h4>￥24,400</h4>
                      <small>あなたの思う理想のボディへ！<br>月会費込・月4回パーソナルレッスン</small>
                    </b-card>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    class="pb-2"
                  >
                    <b-card
                      text-variant="black"
                      header="ダイエットプログラム"
                      class="text-center"
                    >
                      <h4>￥120,000</h4>
                      <small>全18回（60分）パーソナルレッスン<br>ヨガレッスン通い放題</small>
                    </b-card>
                  </b-col>
                  <b-col cols="12">
                    <strong>※表示価格は全て税込です</strong>
                  </b-col>
                </b-row>
              </b-card-group>
            </div>
          </b-col>
        </b-row>

        <b-row
          class="mb-4 mt-2 "
        >
          <b-col cols="12">
            <b-row class="p-4" >
              <b-col
                ms="6"
                md="6"
                class="p-text-left"
              >
                <div class="d-flex ">
                  <img
                    src="../assets/sp-img/Icon awesome-shopping-bag.png"
                    width="35px"
                    height="40px"
                    alt=""
                  >
                  <div class="text-li-title"><p class="text-color">ご入会の際にお持ちいただくもの</p> </div>
                </div>
                <ul class="lis-style">
                  <li><small class="text-li">入会金 (税込 ￥10,000)</small></li>
                  <li><small class="text-li">当月会費</small></li>
                  <li><small class="text-li">翌月会費 ※現金またはクレジット</small></li>
                  <li><small class="text-li">身分証明書</small></li>
                </ul>
              </b-col>
              <!-- b-col
                ms="6"
                md="6"
                class="p-text-left"
              >
                <div class="d-flex ">
                  <img
                    src="../assets/sp-img/Icon awesome-check-square.png"
                    width="35px"
                    height="35px"
                    alt=""
                  >
                  <div class="text-li-title"><p class="text-color">Information</p> </div>
                </div>
                <ul class="hide-lis-style">
                  <li><small class="text-li">入会登録手数料：￥10,000 </small></li>
                  <li><small class="text-li">※入会時に会員の種類をお選びください。</small></li>
                  <li><small class="text-li">※表示価格は全て税込です。</small></li>
                </ul>
              </b-col -->
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Service section -->
    <div class="Service-warp pt-5 pb-5">
      <b-container class="bv-example-row pt-4">
        <div class="each_title pb-5 mb-4">
          <div
            class="title"
          >Service</div>
          <p class="divide-title" />
          <small class="sub-title">便利なサービスのご案内</small>
        </div>
        <b-row>
          <b-col
            sm="6"
            md="6"
          >
            <div class="text-center justify-content-center">
              <lazy-image
                  :src='imgservice'
                  :placeholder='loadingimage'
                  width="90%"
                  style="border-radius: 40px;"
              />
            </div>
          </b-col>
          <b-col
            sm="6"
            md="6"
          >
            <div class="pt-5 p-3 mt-3">
              <p class="text-color">お仕事帰りやお買い物の前後など、お気軽にお越しいただけます！</p><br><br>
              <p class="text-color">シューズお預かり ￥1,000 / 月</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>


    <div class="Service-warp-last mt-5 mb-5">
      <b-container class="service-bg ">
        <b-row>
          <b-col
            sm="6"
            md="6"
            class="service-last-left"
          >
            <div>
              <div class="d-flex ">
                <img
                  src="../assets/sp-img/n-one.png"
                  width="7%"
                  height="7%"
                  alt=""
                  style="padding-top: 9px;"
                >
                <p class="text text-color">インターネットからお申込み下さい。<br>
                  日時を決めてご予約をしたら<br>
                  ・動きやすい服装・タオル・お水<br>
                  をご準備して スタジオまでお越しください。<br>
                </p>
              </div>
              <div class="d-flex ">
                <img
                  style="margin-top: -9px"
                  src="../assets/sp-img/n-two.png"
                  width="7%"
                  height="7%"
                  alt=""
                >
                <p class="text text-color">
                  ウェアに着替え、レッスンの準備をします。
                </p>
              </div>
              <div class="d-flex" style="padding-top: 28px;">
                <img
                  src="../assets/sp-img/n-three.png"
                  width="7%"
                  height="7%"
                  alt=""
                  style="padding-top: 15px;"
                >
                <p
                  class="text-color"
                  style="padding-left: 25px;"
                >
                  レッスンを開始します。<br>
                  しっかりと動ける範囲を把握いたしますので 無理のない、レッスンが受けられます。
                </p>
              </div>
            </div>
          </b-col>
          <b-col
            sm="6"
            md="6"
            class="text-center"
          >
            <div class="service-last-right text-center">
              <div>
                <h3 style="font-weight:400;color:#707770">trial  lesson</h3>
              </div>
              <div class="buttom-booking ">
                <p class="text-color">
                  インターネットからお申込み下さい。<br><br>
                  <b-link href="http://repitte.jp/reserve/store?token=0d2e342b053dc3da29fdf05a661fcb5b" target="_blank">体験レッスン予約</b-link>
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <b-link href="https://www.instagram.com/karunayoga_fukuoka/" target="_blank">
                  <img
                    src="../assets/sp-img/Icon awesome-instagram.png"
                    width="50%"
                    alt=""
                  >
                </b-link>
                <b-link href="https://line.me/R/ti/p/%40915qiccb" target="_blank">
                  <img
                    src="../assets/sp-img/Icon awesome-line.png"
                    width="50%"
                    alt=""
                  >
                </b-link>
              </div>

            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

  </div>
</template>
<script>
import require from 'jquery'
  export default {
    data: () => ({
      imgservice: require('@/assets/sp-img/service-img-wrap.webp'),
       loadingimage:require('@/assets/loading-image.gif'),
      }),
  }
</script>
