

<template>
  <div
    id="price"
    class="price pb-5"
  >
    <div class="price-top">
      <div class="top-img">
        <b-container>
          <b-row>
            <b-col cols="12">
        <h3>Privacy Policy </h3>
        <b class="title">プライバシーポリシー</b>
           </b-col>
          </b-row>
        </b-container>
      </div>
    </div>


    <div class="price-warp pt-5 pb-5 mb-4">
      <b-container class="bv-example-row pt-4">
        <div class="each_title">
          <div
            class="title"
          >Privacy Policy</div>
          <p class="divide-title" />
          <small class="sub-title">プライバシーポリシー</small>
        </div>
      </b-container>
    </div>

    <div class="group-lesson">
      <b-container
        fruid
        class="pt-4"
      >
        <b-row class="privacy-policy">
          <b-col cols="12">
            <div class="title"><h3>1. プライバシー保護の重要性</h3></div>
            <div class="text">
               <p>
			     当社、ＭＹＢＭ株式会社（以下、「弊社」）は、お客様のプライバシーを尊重し、個人情報を適切に取り扱うことに全力を尽くします。本プライバシーポリシーは、お客様の個人情報をどのように収集、利用、保管、開示、および保護するかについて説明いたします。
			   </p>
            </div>

			<div class="title"><h3>2. 収集する情報</h3></div>
            <div class="text">
               <p>
			    弊社は、予約において、お名前、連絡先情報（電話番号、メールアドレスなど）、お支払い情報（クレジットカード情報など）、予約詳細情報を収集する場合があります。
			   </p>
            </div>

			<div class="title"><h3>3. 情報の利用</h3></div>
            <div class="text">
               <p>
			   弊社は、お客様の情報を以下の目的のために利用します。<br>
- 予約の確認および管理<br>
- サービス提供およびお問い合わせへの対応<br>
- キャンセルポリシーの適用<br>
- お客様への連絡
			   </p>
            </div>

			<div class="title"><h3>4. 情報の共有.</h3></div>
            <div class="text">
               <p>
			   弊社は、お客様の情報を第三者と共有することはありませんが、法的要求に応じた場合やお客様からの明示的な同意を得た場合を除きません。
			   </p>
            </div>

			<div class="title"><h3>5. セキュリティ</h3></div>
            <div class="text">
               <p>
			  弊社はお客様の情報を適切に保護するために、セキュリティ対策を講じます。お客様のデータは、不正アクセス、紛失、破損、漏洩などから保護されます。
			   </p>
            </div>

			<div class="title"><h3>6. お客様の権利</h3></div>
            <div class="text">
               <p>
			 お客様は、ご自身の個人情報へのアクセス、訂正、削除をリクエストする権利を有します。また、個人情報の利用についての同意を撤回する権利もあります。
			   </p>
            </div>

			<div class="title"><h3>7. プライバシーポリシーの変更</h3></div>
            <div class="text">
               <p>
			弊社は、プライバシーポリシーを変更する場合があります。変更が行われた場合、最新の情報は弊社ウェブサイト上で提供されます。
			   </p>
            </div>

			<div class="title"><h3>8. お問い合わせ</h3></div>
            <div class="text">
               <p>
			プライバシーポリシーに関する質問、疑念、およびリクエストについては、以下の連絡先までお問い合わせください。<br>
[お問い合わせ先情報]公式LINEにて受付
			   </p>
            </div>


			<div class="text">
               <p>
			本プライバシーポリシーは、お客様と弊社との信頼関係を維持し、お客様のプライバシーを守るために制定されました。お客様にとっての安心感と満足度を最優先に考え、お客様の個人情報を適切に管理いたします。
			   </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div> 
  </div>
</template>
<style>
    @media (min-width: 1024px){
		.privacy-policy{
			padding: 0 12%;
		}
	}
	.privacy-policy .title h3{
			color: rgb(112, 119, 112);
			font-weight: 500 !important;
			font-size: 22px;
		}
	.privacy-policy .text{
		padding-bottom: 15px;
	}
	.privacy-policy .text p{
		color: var(--bs-body-color);

	}
</style>
<script>
import require from 'jquery'
  export default {
    data: () => ({
      imgservice: require('@/assets/sp-img/service-img-wrap.webp'),
       loadingimage:require('@/assets/loading-image.gif'),
      }),
  }
</script>
